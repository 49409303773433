import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  showProductResult,
  showSparePartResult,
  toggleProductLoader,
  toggleSearchLoader,
  resetDropdowns,
  categorySelect,
  subcategorySelect,
  productSelect,
  setDropdownProducts,
  setQuery,
  resetStates,
} from '../actions';
import { debounce } from '../../Common/helpers';

const defaultValue = '';

const selectSearch = (state) => state.Search;
const selectProduct = (state) => state.ActiveProduct;
const selectTranslations = (state) => state.Translations;

const SparePartsSearch = () => {
  const dispatch = useDispatch();

  const controllerRef = useRef(null);

  const searchComponent = useSelector(selectSearch);
  const productComponent = useSelector(selectProduct);
  const translations = useSelector(selectTranslations);

  const [showError, setShowError] = useState(location.search && !productComponent.ProductId);
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [showAutocompleteResults, setShowAutocompleteResults] = useState(false);
  const [showAutocompleteDropdown, setShowAutocompleteDropdown] = useState(false);
  const [filterTimeout, setFilterTimeout] = useState(null);

  const TryAbortRequest = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
  };

  const getErrorMessage = () => {
    return `<p><strong>${searchComponent.Query}</strong></p>${translations.ErrorMessage}`;
  };

  const resetSearch = () => {
    dispatch(resetDropdowns());
  };

  const onCategorySelect = (e) => {
    dispatch(categorySelect(e.target.value));
  };

  const onSubcategorySelect = (e) => {
    dispatch(toggleProductLoader(true));
    dispatch(subcategorySelect(e.target.value));

    TryAbortRequest();

    fetch(`/api/sitecore/spareparts/getproducts?subcategoryid=${e.target.value}`, {
      signal: controllerRef.current?.signal,
    })
      .then((response) => response.json())
      .then((data) => dispatch(setDropdownProducts(data)))
      .then(dispatch(toggleProductLoader(false)))
      .then(() => {
        controllerRef.current = null;
      })
      .catch((error) => {
        if (error.name !== 'AbortError') console.error('Error:', error);
      });
  };

  const onProductSelect = (e) => {
    dispatch(productSelect(e.target.value));
    pushUrl(e.target.value);
    search(e.target.value);
  };

  const pushUrl = (productId) => {
    if (
      searchComponent.ActiveCategoryId !== defaultValue &&
      searchComponent.ActiveSubcategoryId !== defaultValue &&
      productId
    ) {
      window.history.pushState(
        {},
        '',
        window.location.pathname +
          `?c=${searchComponent.ActiveCategoryId}&sc=${searchComponent.ActiveSubcategoryId}&q=${productId}`
      );
    } else {
      resetSearch();
      window.history.pushState({}, '', window.location.pathname + `?q=${productId}`);
    }
  };

  const search = (query) => {
    pushUrl(query);

    dispatch(toggleSearchLoader(true));

    TryAbortRequest();

    fetch(`/api/sitecore/spareparts/search?query=${query}`, { signal: controllerRef.current?.signal })
      .then((response) => response.json())
      .then((data) => {
        dispatch(showProductResult(data));
        dispatch(showSparePartResult(data));
        setShowError(Object.values(data).every((x) => x === null || x === ''));
        dispatch(toggleSearchLoader(false));
      })
      .then(() => {
        controllerRef.current = null;
      })
      .catch((error) => {
        if (error.name !== 'AbortError') console.error('Error:', error);
      });

    setShowAutocompleteResults(false);
    setShowAutocompleteDropdown(false);
  };

  const autoComplete = (query) => {
    dispatch(toggleSearchLoader(true));

    TryAbortRequest();

    fetch(`/api/sitecore/spareparts/autocomplete?query=${query}&maxNumberOfHits=4`, { signal: controllerRef.current?.signal })
      .then((response) => response.json())
      .then((data) => {
        setAutocompleteResults(data);
        dispatch(toggleSearchLoader(false));
      })
      .then(() => {
        controllerRef.current = null;
      })
      .catch((error) => {
        if (error.name !== 'AbortError') console.error('Error:', error);
      });
  };

  const doAutocomplete = (query) => {
    if (!query) return;

    debounce(
      () => {
        if (query.length >= 3) autoComplete(query);
      },
      filterTimeout,
      setFilterTimeout
    );
  };

  const onSearch = () => {
    if (autocompleteResults.length === 0) {
      doAutocomplete(searchComponent.Query);
      setShowAutocompleteResults(true);
    } else {
      setShowAutocompleteResults(true);
      setShowAutocompleteDropdown(false);
    }
    dispatch(resetStates());
    resetSearch();
  };

  return (
    <>
      <div className="thule-container--full-width spare-part-selector bg-color--thule-lightest-grey">
        <div className="d-flex flex-column justify-content-center header">
          <h1 className="heading2">{translations.MainHeader}</h1>
          <h6>{translations.Subheader}</h6>
        </div>
        <div className="d-flex justify-content-center selectors">
          <div className="select-wrapper">
            <label className="select">
              <select
                id="selectCategory"
                name="select_category"
                aria-labelledby="select_category_help"
                onChange={onCategorySelect}
                value={searchComponent.ActiveCategoryId}
              >
                <option value={defaultValue} disabled id="select_category_help">
                  {translations.SelectCategory}
                </option>
                {searchComponent.DropdownCategories.sort((a, b) => a.Name.localeCompare(b.Name)).map((dd) => (
                  <option key={dd.PimIds} value={dd.Id}>
                    {dd.Name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="select-wrapper">
            <label className="select">
              <select
                id="selectSubCategory"
                name="select_subcategory"
                aria-labelledby="select_subcategory_help"
                onChange={onSubcategorySelect}
                value={searchComponent.ActiveSubcategoryId}
                disabled={!searchComponent.ActiveCategoryId}
              >
                <option value={defaultValue} disabled id="select_subcategory_help">
                  {translations.SelectSubcategory}
                </option>
                {searchComponent.DropdownSubcategories.sort((a, b) => a.Name.localeCompare(b.Name)).map((dd) => (
                  <option key={dd.PimIds} value={dd.Id}>
                    {dd.Name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="select-wrapper">
            <label className="select">
              <select
                id="selectProduct"
                name="select_product"
                aria-labelledby="select_product_help"
                onChange={onProductSelect}
                value={searchComponent.ActiveProductId}
                disabled={!searchComponent.ActiveSubcategoryId}
              >
                <option value={defaultValue} disabled id="select_product_help">
                  {translations.SelectProduct}
                </option>
                {searchComponent.DropdownProducts.map((ddp) => (
                  <option key={ddp.PimId} value={ddp.PimId}>
                    {ddp.Name}
                  </option>
                ))}
              </select>
            </label>
            <div className={searchComponent.ShowProductLoader ? 'loader-bar visible' : 'loader-bar invisible'}></div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center info">
          <h6>{translations.Or}</h6>
          <p id="productfinder_help" dangerouslySetInnerHTML={{ __html: translations.SearchInstructions }}></p>
        </div>
        <div>
          <div className="d-flex justify-content-center input-wrapper">
            <input
              aria-labelledby="productfinder_help"
              id="search-spare-parts"
              type="text"
              placeholder={translations.SearchPlaceholder}
              value={searchComponent.Query}
              onChange={(e) => {
                setShowError(false);
                dispatch(setQuery(e.target.value));
                setShowAutocompleteResults(false);
                doAutocomplete(e.target.value);
                setShowAutocompleteDropdown(true);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSearch();
                }
              }}
            />
            <button className="btn btn-md thule-btn-default" onClick={() => onSearch()}>
              {translations.Search}
            </button>
            {autocompleteResults.length > 0 && showAutocompleteDropdown && (
              <div className="autocomplete-results">
                {autocompleteResults.map((res) => (
                  <p
                    key={res.ProductId}
                    onClick={() => {
                      search(res.ProductId);
                      resetSearch();
                    }}
                  >
                    <strong>{res.ProductName}</strong>
                    {res.Color && <span> | {res.Color}</span>}
                    {res.PimId && <span> | {res.PimId}</span>}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
        <div
          className={
            searchComponent.ShowSearchLoader
              ? 'loader-bar loader-bar-search visible'
              : 'loader-bar loader-bar-search invisible'
          }
        ></div>
      </div>
      {showAutocompleteResults && autocompleteResults.length > 0 && (
        <div id="searchResultList">
          <h4 className="text-center">Search Results</h4>
          <div className="search-result-list thule-container--full-width justify-content-center d-flex">
            {autocompleteResults.map((res) => (
              <p key={res.ProductId} onClick={() => search(res.ProductId)}>
                <strong>{res.ProductName}</strong>
                {res.Color && <span> | {res.Color}</span>}
                {res.PimId && <span> | {res.PimId}</span>}
              </p>
            ))}
          </div>
        </div>
      )}
      {showError && (
        <>
          <div className="thule-container--full-width error-message-container d-flex justify-content-center">
            <div className="error-message" dangerouslySetInnerHTML={{ __html: getErrorMessage() }}></div>
          </div>
        </>
      )}
    </>
  );
};

export default SparePartsSearch;
