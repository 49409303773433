export const debounce = (func, filterTimeout, setFilterTimeout, timeout = 300) => {
  if (filterTimeout) {
    clearTimeout(filterTimeout);
    setFilterTimeout(null);
  }

  setFilterTimeout(
    setTimeout(() => {
      func();
    }, timeout)
  );
};
