const prefix = 'spareParts';

export const actions = {
  common: {
    resetStates: `${prefix}/resetState`,
  },
  sparePartsList: {
    toggleModal: `${prefix}/toggleModal`,
  },
  productInfo: {
    showProductResult: `${prefix}/showProductResult`,
    imageZoom: `${prefix}/imageZoom`,
    imageZoomByIcon: `${prefix}/imageZoomByIcon`,
    imageTouchStart: `${prefix}/imageTouchStart`,
    imageTouchMove: `${prefix}/imageTouchMove`,
    imageTouchEnd: `${prefix}/imageTouchEnd`,
    imageMouseMove: `${prefix}/imageMouseMove`,
    resetImageState: `${prefix}/resetImageState`,
  },
  sparePartsSelector: {
    toggleSearchLoader: `${prefix}/toggleSearchLoader`,
    toggleProductLoader: `${prefix}/toggleProductLoader`,
    selectCategory: `${prefix}/selectCategory`,
    showSparePartResult: `${prefix}/showSparePartResult`,
    resetDropdowns: `${prefix}/resetDropdowns`,
    categorySelect: `${prefix}/categorySelect`,
    subcategorySelect: `${prefix}/subcategorySelect`,
    productSelect: `${prefix}/productSelect`,
    setDropdownProducts: `${prefix}/setDropdownProducts`,
    setQuery: `${prefix}/setQuery`,
  },
};

export const showProductResult = (productData) => {
  return {
    type: actions.productInfo.showProductResult,
    payload: productData,
  };
};

export const showSparePartResult = (productData) => {
  return {
    type: actions.sparePartsSelector.showSparePartResult,
    payload: productData.SpareParts,
  };
};

export const selectCategory = (category) => {
  return {
    type: actions.sparePartsSelector.selectCategory,
    payload: category,
  };
};

export const toggleProductLoader = (shouldShow) => {
  return {
    type: actions.sparePartsSelector.toggleProductLoader,
    payload: shouldShow,
  };
};

export const toggleSearchLoader = (shouldShow) => {
  return {
    type: actions.sparePartsSelector.toggleSearchLoader,
    payload: shouldShow,
  };
};

export const toggleModal = (shouldShow) => {
  return {
    type: actions.sparePartsList.toggleModal,
    payload: shouldShow,
  };
};

export const imageTouchStart = (target) => {
  return {
    type: actions.productInfo.imageTouchStart,
    payload: target,
  };
};

export const imageTouchMove = (target) => {
  return {
    type: actions.productInfo.imageTouchMove,
    payload: target,
  };
};

export const imageTouchEnd = () => {
  return {
    type: actions.productInfo.imageTouchEnd,
  };
};

export const imageMouseMove = (target) => {
  return {
    type: actions.productInfo.imageMouseMove,
    payload: target,
  };
};

export const imageZoom = (target) => {
  return {
    type: actions.productInfo.imageZoom,
    payload: target,
  };
};

export const resetImageState = (target) => {
  return {
    type: actions.productInfo.resetImageState,
    payload: target,
  };
};

export const resetDropdowns = () => {
  return {
    type: actions.sparePartsSelector.resetDropdowns,
  };
};

export const categorySelect = (categoryId) => {
  return {
    type: actions.sparePartsSelector.categorySelect,
    payload: categoryId,
  };
};

export const subcategorySelect = (subcategoryId) => {
  return {
    type: actions.sparePartsSelector.subcategorySelect,
    payload: subcategoryId,
  };
};

export const productSelect = (productId) => {
  return {
    type: actions.sparePartsSelector.productSelect,
    payload: productId,
  };
};

export const setDropdownProducts = (productArray) => {
  return {
    type: actions.sparePartsSelector.setDropdownProducts,
    payload: productArray,
  };
};

export const setQuery = (value) => {
  return {
    type: actions.sparePartsSelector.setQuery,
    payload: value,
  };
};

export const resetStates = () => {
  return {
    type: actions.common.resetStates,
  };
};
